import { useEffect, useMemo, useState } from 'react';

import { useUserSettingsAnalytics } from '../../analytics/userSettings';
import { OrganizerRoleUtils } from '../../types/organization';
import { LogoutButton } from '../Access';
import { AccountIcon } from '../icons/AccountIcon';
import { BillingIcon } from '../icons/BillingIcon';
import { BrainIcon } from '../icons/BrainIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { TeamIcon } from '../icons/TeamIcon';
import {
  OrganizationDetailsMembers,
  OrganizationSettings,
} from '../Organization/Details';
import { OrgConnection } from '../Organization/OrgConnection';
import { useUser } from '../UserContext';
import { Account } from './Account';
import { AISettings } from './AISettings';
import { BillingSettings } from './Billing';
import { OrganizationPanel } from './Org';
import { type LearningMenu, LearningMenuKey } from './types';

const menuMap: { [key in LearningMenuKey]: LearningMenu } = {
  [LearningMenuKey.Account]: {
    key: LearningMenuKey.Account,
    title: 'My Account',
    icon: <AccountIcon className='w-4 h-4 fill-current' />,
    content: () => <Account />,
  },
  [LearningMenuKey.OrgMembers]: {
    key: LearningMenuKey.OrgMembers,
    title: 'Manage Members',
    disableTitleInBody: true,
    icon: <TeamIcon className='w-4 h-4 fill-current' />,
    content: (props) => (
      <OrganizationPanel user={props.user}>
        <OrganizationDetailsMembers />
      </OrganizationPanel>
    ),
  },
  [LearningMenuKey.OrgSettings]: {
    key: LearningMenuKey.OrgSettings,
    title: 'Organization Settings',
    disableTitleInBody: true,
    icon: <SettingIcon className='w-4 h-4 fill-current' />,
    content: (props) => (
      <OrganizationPanel user={props.user}>
        <OrganizationSettings learning />
      </OrganizationPanel>
    ),
  },
  [LearningMenuKey.Billing]: {
    key: LearningMenuKey.Billing,
    title: 'Billing',
    disableTitleInBody: true,
    icon: <BillingIcon className='w-4 h-4 fill-current' />,
    content: () => <BillingSettings />,
  },
  [LearningMenuKey.AI]: {
    key: LearningMenuKey.AI,
    title: 'AI Knowledge',
    icon: <BrainIcon className='w-4 h-4 stroke-current' />,
    content: (props) => (
      <OrganizationPanel user={props.user}>
        <AISettings />
      </OrganizationPanel>
    ),
  },
};

function getMenuByKey(
  key: LearningMenuKey | undefined
): LearningMenu | undefined {
  if (!key) return;
  return menuMap[key];
}

function SettingsInternal(props: {
  enabledMenuKeys: LearningMenuKey[];
  initialMenuKey?: LearningMenuKey;
  selectedMenuKey?: LearningMenuKey;
  onMenuSelected?: (key: LearningMenuKey) => void;
}) {
  const { enabledMenuKeys, initialMenuKey, selectedMenuKey, onMenuSelected } =
    props;
  const menus = useMemo(
    () =>
      enabledMenuKeys
        .map((k) => getMenuByKey(k))
        .filter((m): m is LearningMenu => !!m),
    [enabledMenuKeys]
  );
  const user = useUser();
  const [uncontrolledMenu, setUncontrolledMenu] = useState<
    LearningMenu | undefined
  >(getMenuByKey(initialMenuKey ?? enabledMenuKeys[0]));
  const activeMenu = useMemo(() => {
    const isControlled = selectedMenuKey !== undefined;
    return isControlled ? getMenuByKey(selectedMenuKey) : uncontrolledMenu;
  }, [selectedMenuKey, uncontrolledMenu]);
  const analytics = useUserSettingsAnalytics();

  useEffect(() => {
    if (!activeMenu?.key) return;
    // Emit the event whenever the key changes, including when the component
    // first mounts
    analytics.trackUserSettingsPanelViewed(activeMenu.key);
  }, [activeMenu?.key, analytics]);

  if (!user) return null;

  const handleChangeActiveMenu = (m: LearningMenu) => {
    setUncontrolledMenu(m);
    onMenuSelected?.(m.key);
  };

  return (
    <div className='w-full h-full bg-black overflow-hidden flex flex-row'>
      <aside className='w-68 h-full p-2 bg-[#101012] text-white text-sm flex flex-col justify-between pb-2 flex-shrink-0'>
        <ul>
          {menus.map((m) => {
            return (
              <li
                key={m.key}
                className={`${
                  activeMenu?.key === m.key
                    ? 'bg-lp-gray-002 rounded-xl border border-black'
                    : 'hover:btn-primary'
                } mb-1 px-4 py-2.5 flex items-center btn cursor-pointer`}
                onClick={() => {
                  handleChangeActiveMenu(m);
                }}
              >
                {m.icon}
                <span className='ml-2'>{m.title}</span>
              </li>
            );
          })}
        </ul>

        <div className='w-full flex flex-col items-center gap-3'>
          {user.organizer?.organization && (
            <OrgConnection org={user.organizer.organization} />
          )}
          <LogoutButton className='w-full h-11 text-icon-gray' />
        </div>
      </aside>

      {activeMenu && (
        <div className='w-full p-6 h-full overflow-y-auto scrollbar'>
          {!activeMenu.disableTitleInBody && (
            <header className='text-white text-2xl mb-6'>
              {activeMenu.title}
            </header>
          )}
          {activeMenu.content({
            user: user,
          })}
        </div>
      )}
    </div>
  );
}

function useLearningMenuKeys() {
  const user = useUser();
  const orgManageable = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);

  return useMemo(() => {
    const menuKeys: LearningMenuKey[] = [LearningMenuKey.Account];

    if (orgManageable) {
      menuKeys.push(LearningMenuKey.OrgMembers);
      menuKeys.push(LearningMenuKey.OrgSettings);
      menuKeys.push(LearningMenuKey.Billing);
      menuKeys.push(LearningMenuKey.AI);
    }

    return menuKeys;
  }, [orgManageable]);
}

export function SettingsModal(props: {
  onClose: () => void;
  initialMenuKey?: LearningMenuKey;
  selectedMenuKey?: LearningMenuKey;
  onMenuSelected?: (key: LearningMenuKey) => void;
}) {
  const enabledMenuKeys = useLearningMenuKeys();
  return (
    <div className='absolute left-0 top-0 z-50 w-full h-full bg-black flex flex-col'>
      <header className='w-full py-2 px-4 flex flex-row justify-between items-center border-b border-gray-800'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-xl text-white'>Settings</p>
        </div>
        <button
          type='button'
          className='btn-secondary w-34 h-10'
          onClick={props.onClose}
        >
          Close
        </button>
      </header>

      <SettingsInternal enabledMenuKeys={enabledMenuKeys} {...props} />
    </div>
  );
}

export function LearningSettings(props: {
  selectedMenuKey?: LearningMenuKey;
  onMenuSelected?: (key: LearningMenuKey) => void;
}) {
  const enabledMenuKeys = useLearningMenuKeys();
  return <SettingsInternal enabledMenuKeys={enabledMenuKeys} {...props} />;
}
