import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { NotificationCenter } from '../components/Notification';
import {
  NotificationContextProvider,
  useNotificationDataSourceReactState,
} from '../components/Notification/Context';
import { ProvidersList } from '../components/ProvidersList';
import { LearningSettings } from '../components/Settings/LearningSettings';
import { LearningMenuKey } from '../components/Settings/types';
import { learningSettingsPath } from '../components/Settings/utils';
import { LearningLayout } from '../components/Training/Admin/Layout/LearningLayout';
import { UserContextProvider, useUser } from '../components/UserContext';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { store } from '../store/configureStore';
import { RoleUtils } from '../types';
import { makeTitle } from '../utils/common';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

function LearningSettingsPage() {
  const { selectedMenuKey } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const handleMenuSelected = useCallback(
    (menuKey: LearningMenuKey) => {
      navigate(learningSettingsPath(menuKey));
    },
    [navigate]
  );

  return (
    <LearningSettings
      selectedMenuKey={selectedMenuKey}
      onMenuSelected={handleMenuSelected}
    />
  );
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const selectedMenuKey = action.params.tab
    ? Object.values(LearningMenuKey).find(
        (enumValue) => enumValue === action.params.tab
      )
    : LearningMenuKey.Account;

  return { selectedMenuKey };
}

function ComponentInternal() {
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);

  if (isAdmin) {
    return (
      <AdminView>
        <LearningSettingsPage />
      </AdminView>
    );
  } else {
    return (
      <LearningLayout>
        <LearningSettingsPage />
      </LearningLayout>
    );
  }
}

export function Component() {
  useTitle(makeTitle('Settings'));
  const notificationDataSource = useNotificationDataSourceReactState();

  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <NotificationContextProvider datasource={notificationDataSource} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <ComponentInternal />
      <NotificationCenter
        className='!w-108'
        position='bottom-right'
        toastClassName='rounded my-2'
      />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
